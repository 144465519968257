<template>
  <div class="weixinshu-7th">
    <div class="top">
      <!-- <div class="all-rule">
        <img src="https://img.xinshu.me/upload/76e8342f2216480d9bbc84edb1198c4f" alt="">
        <div class="text">活动规则</div>
      </div> -->
      <div class="title">
        <square src="https://img.xinshu.me/upload/7c59006a96754ff09b9a8ae7807c6f21" alt="" plain/>
      </div>
      <div class="subtitle">
        <square src="https://img.xinshu.me/upload/a03b6af2af564778aae5746a69c17f40" height="12.1%"
                plain/>
      </div>
      <div class="act-box">
        <div class="item act-1" @click="go7year">
          <square src="https://img.xinshu.me/upload/1f9bee6f06a44b7e97c68df5fc9743e4" plain
                  height="80.8%"/>
        </div>
        <div class="item act-2" @click="prizeRules">
          <square src="https://img.xinshu.me/upload/c1386fb38ffa422a9b7cbe983c50277a" plain
                  height="80.8%"/>
        </div>
      </div>
      <div class="guides">
        <swiper class="guide-swiper" :options="swiperOption">
          <swiper-slide v-for="(item, idx) in bannerList" :key="idx">
            <div class="guide-item">
              <img :src="item.pic" :data-url="item.link"/>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="content">
      <div class="tab-box">
        <div class="activity-tab" :class="{'active': showTab === item.tag}" v-for="item in tabList"
             :key="item.tag" @click="clickTab(item)">
          <div class="time">{{item.time}}</div>
          <div class="name">{{item.name}}</div>
        </div>
      </div>
      <div class="activity-box">
        <div class="activity-load" v-if="tabLoading">
          <fa icon="spinner" spin/>
          &nbsp;加载中...
        </div>
        <template v-else>
          <div class="content invite" v-show="showTab === 0">
            <div class="activity-blank" v-show="!tabList[0].open">
              <div class="blank-pic">
                <img src="https://img.xinshu.me/upload/ab89fcf72ae94e69a67222202b5c9b0a" alt="">
              </div>
              <div class="blank-text">活动未开始，请耐心等待哦！</div>
            </div>
            <div class="activity-content" v-show="tabList[0].open">
              <div class="activity-ti">邀请好友，A4杂志册免费送</div>
              <div class="activity-rule">
                <img src="https://img.xinshu.me/upload/76e8342f2216480d9bbc84edb1198c4f" alt="">
                <div class="text" @click="inviteRules">邀请规则</div>
              </div>
              <div class="invite-box">
                <swiper class="invite-swiper" :options="inviteSwiperOption" v-if="showFans">
                  <swiper-slide v-for="(item, idx) in fansList" :key="idx">
                    <div class="invite-item"><img :src="item.headimgurl" alt=""></div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </div>
              <div class="invite-text">再邀请{{inviteFans}}位好友，即可获得杂志册</div>
              <div class="activity-btn"
                   :class="{'activity-btn-unable': tabList[0].close}"
                   @click="tabList[0].close ? '' : invitePopup = true"
              >
                {{tabList[0].close ? '活动结束' : '邀请好友'}}
              </div>
            </div>
          </div>
          <div class="content sign" v-show="showTab === 1">
            <div class="activity-blank" v-show="!tabList[1].open">
              <div class="blank-pic"><img
                src="https://img.xinshu.me/upload/ab89fcf72ae94e69a67222202b5c9b0a" alt=""></div>
              <div class="blank-text">活动未开始，请耐心等待哦！</div>
            </div>
            <div class="activity-content" v-show="tabList[1].open">
              <div class="activity-ti">
                {{signTitle}}
              </div>
              <div class="activity-rule">
                <img src="https://img.xinshu.me/upload/76e8342f2216480d9bbc84edb1198c4f" alt="">
                <div class="text" @click="signRules">签到规则</div>
              </div>
              <div class="sign-box">
                <div class="sign-item" v-for="(item, name, idx) in signinObj" :key="name">
                  <img src="https://img.xinshu.me/upload/0e6fafb91d564af195812b45d91a28e2" alt="">
                  <div class="text">第{{idx + 1}}天</div>
                  <div class="checked" v-show="item">
                    <fa icon="check"/>
                  </div>
                </div>
              </div>
              <div class="sign-remind" @click="signPopup = true">签到提醒</div>
              <div class="activity-btn" :class="{'activity-btn-unable': tabList[1].close}" @click="signin">
                {{tabList[1].close ? '活动结束' : (signinData.todaySigned ? '分享给好友' : '签 到')}}
              </div>
            </div>
          </div>
          <div class="content discount" v-show="showTab === 2">
            <div class="activity-blank" v-show="!tabList[2].open">
              <div class="blank-pic"><img
                src="https://img.xinshu.me/upload/ab89fcf72ae94e69a67222202b5c9b0a" alt=""></div>
              <div class="blank-text">活动未开始，请耐心等待哦！</div>
            </div>
            <div class="activity-content" v-show="tabList[2].open">
              <div class="activity-ti">周年庆典 史低折扣</div>
              <div class="activity-pic">
                <img src="https://img.xinshu.me/upload/dee64de0f6c44d5bbbab60f492f8db03" alt="">
              </div>
              <div class="discount-tip">A4版本/精装版本享7折，PDF电子书享5折</div>
              <div class="activity-btn" :class="{'activity-btn-unable': tabList[2].close}" @click="goDiscount">
                {{tabList[2].close ? '活动结束' : '前往做书'}}
              </div>
            </div>
          </div>
          <div class="content recharge" v-show="showTab === 3">
            <div class="activity-blank" v-show="!tabList[3].open">
              <div class="blank-pic"><img
                src="https://img.xinshu.me/upload/ab89fcf72ae94e69a67222202b5c9b0a" alt=""></div>
              <div class="blank-text">活动未开始，请耐心等待哦！</div>
            </div>
            <div class="activity-content" v-show="tabList[3].open">
              <div class="activity-ti">充值特惠 今年首发超值赠券</div>
              <div class="recharge-box">
                <div class="recharge-item">
                  <div class="fan"><span class="small">送</span>7折<span class="small">券</span></div>
                  <div class="chong">充1000元</div>
                </div>
                <div class="recharge-item">
                  <div class="fan"><span class="small">送</span>100<span class="small">元</span></div>
                  <div class="chong">充500元</div>
                  <div class="tip">优惠券有效期1年</div>
                </div>
                <div class="recharge-item">
                  <div class="fan"><span class="small">送</span>32<span class="small">元</span></div>
                  <div class="chong">充200元</div>
                </div>
              </div>
              <div class="activity-btn" :class="{'activity-btn-unable': tabList[3].close}" @click="goRecharge">
                {{tabList[3].close ? '活动结束' : '前往充值'}}
              </div>
            </div>
          </div>
          <div class="content spike" v-show="showTab === 4">
            <div class="activity-blank" v-show="!tabList[4].open">
              <div class="blank-pic"><img
                src="https://img.xinshu.me/upload/ab89fcf72ae94e69a67222202b5c9b0a" alt=""></div>
              <div class="blank-text">活动未开始，请耐心等待哦！</div>
            </div>
            <div class="activity-content" v-show="tabList[4].open">
              <div class="activity-ti">做书囤券 低至4.8折</div>
              <div class="middle-pic">
                <img src="https://img.xinshu.me/resource/86208945a3404e5d8158f86155828c8e" alt="">
              </div>
              <div class="activity-btn" :class="{'activity-btn-unable': tabList[4].close}" @click="goSpike">
                {{tabList[4].close ? '活动结束' : '前往抢购'}}
              </div>
            </div>
          </div>
          <div class="content game" v-show="showTab === 5">
            <div class="activity-blank" v-show="!tabList[5].open">
              <div class="blank-pic"><img
                src="https://img.xinshu.me/upload/ab89fcf72ae94e69a67222202b5c9b0a" alt=""></div>
              <div class="blank-text">活动未开始，请耐心等待哦！</div>
            </div>
            <div class="activity-content" v-show="tabList[5].open">
              <div class="activity-ti">颜色大比拼 测测你的色差辨识度</div>
              <div class="middle-pic">
                <img src="https://img.xinshu.me/resource/11c7ef9d718a4fa88f16023e676c3eb5" alt="">
              </div>
              <div class="activity-btn" :class="{'activity-btn-unable': tabList[5].close}" @click="goGame">
                {{tabList[5].close ? '活动结束' : '开始游戏'}}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="bottom">
      <div class="report" @click="gzhPopup = true">
        <square src="https://img.xinshu.me/upload/485843dfb22e4644be8e205ecffb5f7a" height="25%"/>
      </div>
      <div class="recommend-box">
        <div class="subtitle"><img
          src="https://img.xinshu.me/upload/1ae371eb60a34f6c86be8c9a0f72729b" alt=""></div>
        <div class="item-outer">
          <div class="item-wrap">
            <div class="item" v-for="(val, idx) in recommendList" :key="idx" @click="jumpLink(val)">
              <img class="pic" :src="val.pic" alt="">
              <div class="ti">{{val.title}}</div>
              <div class="info">{{val.info}}</div>
              <div class="price">{{val.price}}<span class="qi"></span> <span class="old-price"
                                                                             v-if="val.oldPrice">{{val.oldPrice}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup v-if="gzhPopup" @close="gzhPopup = false">
      <b-card class="mb-0">
        <b-card-title>进入公众号</b-card-title>
        <square src="https://img.xinshu.me/upload/7a230e01fb854817a6f56d31e2260bbf"
                style="max-width: 200px;" class="mx-auto"/>
        <div class="mt-3">
          长按识别二维码，领取我的朋友圈报告
        </div>
      </b-card>
    </popup>
    <popup v-if="signPopup" @close="signPopup = false">
      <b-card class="mb-0">
        <b-card-title>进入公众号</b-card-title>
        <square src="https://img.xinshu.me/upload/c5332cf7585d415fbeb79a2df0d902ee"
                style="max-width: 200px;" class="mx-auto"/>
        <div class="mt-3">
          长按识别二维码，公众号每天自动提醒
        </div>
      </b-card>
    </popup>
    <popup v-if="invitePopup" guide="保存图片并分享给好友" :src="invitePoster" @close="invitePopup = false"/>
    <popup v-if="signinPopup" guide="保存图片并分享给好友" :src="signPoster" @close="signinPopup = false"/>
  </div>
</template>

<script>
import { configShare } from '@/modules/wechat'

let vm = null
export default {
  name: 'weixinshu7th',
  title: '微信书7周年',
  mixins: [require('@/mixins/swiper').default],
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        on: {
          click: function () {
            const realIndex = this.realIndex
            vm.jumpLink(vm.bannerList[realIndex])
          }
        },
        slidesPerView: 'auto',
        loopedSlides: 4,
        centeredSlides: true,
        spaceBetween: 14
      },
      inviteSwiperOption: {
        slidesPerView: 3,
        spaceBetween: 18,
        slidesPerGroup: 3,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      bannerList: [
        {
          pic: 'https://img.xinshu.me/upload/7a7333cc821b439cae214734d699471f',
          link: 'https://weixinshu.com/products/wxbook',
          value: 'A4多彩童趣'
        }, {
          pic: 'https://img.xinshu.me/upload/693ba3b834c441f086e4d343b002d115',
          link: 'https://weixinshu.com/products/wxbook',
          value: 'A5双栏'
        }, {
          pic: 'https://img.xinshu.me/upload/af390699c5e146839c6844ce761392ca',
          link: 'https://weixinshu.com/products/wbbook',
          value: '微博书'
        }, {
          pic: 'https://img.xinshu.me/upload/aee52dd4265b4046863e7bf4312365d1',
          link: 'https://weixinshu.com/products/frame-moment',
          value: '朋友圈相框'
        }
      ],
      recommendList: [
        {
          pic: 'https://img.xinshu.me/upload/4b6188c050ed44e691dfebc51cdf4481',
          link: 'https://weixinshu.com/packages/IXXMRL',
          value: '锁线照片书',
          title: '锁线照片书3件套',
          info: '3本 | 每本95页',
          price: '￥228.00',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/4dc0f56daaad41a5bcceab284be5669f',
          link: 'https://weixinshu.com/packages/HESIQZ',
          value: '心书皮面册',
          title: '皮面册12件套',
          info: '12本 | 每本19页',
          price: '￥199.00',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/09fca68d53cf4516a2ee4d581d33f688',
          link: 'https://weixinshu.com/scenarios/50d8a15ba1c8c',
          value: '创意绘本(男孩版)',
          title: '创意绘本(男孩版)',
          info: '16页 | 上传照片自动抠图',
          price: '￥68.00',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/d0672f8e2ff14cba8ed80d5de24961ae',
          link: 'https://weixinshu.com/scenarios/a24e929f8849f',
          value: 'B5锁线书',
          title: '美好童年·B5锁线书',
          info: '95页 | 可传图95张',
          price: '￥98.00',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/a26b0af1ec9845d7887f12dd4a0de48d',
          link: 'https://weixinshu.com/scenarios/a678151d11f59',
          value: 'A5纪念册',
          title: '幸福一家·A5纪念册',
          info: '20~60页 | 可传图10~120张',
          price: '￥68.00起',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/3e29d664ecd943ad88cfde903e6e78d6',
          link: 'https://weixinshu.com/scenarios/aa9f854f30f77',
          value: 'B5照片书',
          title: '旅行意义·B5照片书',
          info: '20~60页 | 可传图20~240张',
          price: '￥58.00起',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/bd80912ea78f43f4b127612a9af52ded',
          link: 'https://weixinshu.com/scenarios/a24456ae2262e',
          value: '12寸写真集',
          title: '邂逅爱情·12寸写真集',
          info: '20页 | 可传图10~40张',
          price: '￥99.00',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/59ca998641e44fd0be2210487c6d2ca6',
          link: 'https://weixinshu.com/scenarios/f868734fa4dbc',
          value: 'A4杂志册',
          title: '青春不散·A4杂志册',
          info: '24页 | 可传图24~96张',
          price: '￥48.00',
          oldPrice: ''
        }
      ],
      tabList: [
        {
          tag: 0,
          time: '8.1-8.31',
          name: '7周年代言',
          startTime: '2021/8/1 00:00:00',
          endTime: '2021/9/1 00:00:00',
          open: true,
          close: false
        }, {
          tag: 1,
          time: '8.4-8.10',
          name: '签到就送',
          startTime: '2021/8/4 00:00:00',
          endTime: '2021/8/11 00:00:00',
          open: true,
          close: false
        }, {
          tag: 2,
          time: '8.10-8.18',
          name: '全场折扣',
          startTime: '2021/8/10 00:00:00',
          endTime: '2021/8/19 00:00:00',
          open: true,
          close: false
        }, {
          tag: 3,
          time: '8.17-8.24',
          name: '充值特惠',
          startTime: '2021/8/17 00:00:00',
          endTime: '2021/8/25 00:00:00',
          open: true,
          close: false
        }, {
          tag: 4,
          time: '8.25-8.26',
          name: '限时抢购',
          startTime: '2021/8/25 00:00:00',
          endTime: '2021/8/27 00:00:00',
          open: true,
          close: false
        }, {
          tag: 5,
          time: '8.25-8.31',
          name: '游戏大PK',
          startTime: '2021/8/25 00:00:00',
          endTime: '2021/9/1 00:00:00',
          open: true,
          close: false
        }
      ],
      showTab: 0,
      signinObj: {
        20210804: false,
        20210805: false,
        20210806: false,
        20210807: false,
        20210808: false,
        20210809: false,
        20210810: false
      },
      signinData: {
        continuousDayCount: 0,
        todaySigned: false,
        days: []
      },
      fansList: [],
      showFans: false,
      inviteFans: 0,
      gzhPopup: false,
      invitePopup: false,
      signPopup: false,
      signinPopup: false,
      qrTicket: '',
      showAllTabs: false,
      tabLoading: true
    }
  },
  computed: {
    signTitle() {
      if (this.tabList[1].close) {
        return '签到活动已结束'
      } else {
        return this.signinData.todaySigned
          ? '今日已签到，' + this.signinData.continuousDayCount * 7 + '元优惠券到账'
          : '今日签到，可获得' + (this.signinData.continuousDayCount + 1) * 7 + '元优惠券'
      }
    },
    invitePoster() {
      const qrcode = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' +
        encodeURIComponent(this.qrTicket)
      return 'https://canvas.xinshu.me/generate/personal-777' + this.serialize({
        avatar: this.user.avatar,
        nickname: this.user.nickname,
        qrcode: qrcode
      })
    },
    signPoster() {
      return 'https://canvas.xinshu.me/generate/7y-qd' + this.serialize({
        avatar: this.user.avatar,
        nickname: this.user.nickname,
        day: this.signinData.continuousDayCount,
        money: (this.signinData.continuousDayCount * 7)
      })
    }
  },
  created() {
    vm = this
    this.showAllTabs = this.$route.query.tab === 'all'
    this.init()
  },
  methods: {
    init() {
      this.initShowTab()
      this.getFans()
      this.getSignin()
      this.fetchProfile()
      configShare({
        title: '微信书7周年 玩转火热8月',
        desc: '邀请有礼|签到就送|全场折扣|充值特惠|限时抢购',
        imgUrl: 'https://img.xinshu.me/upload/cab78072564b45c6844a10b0cd74e89d',
        link: location.href
      })
    },
    async initShowTab() {
      let show = this.$route.query.show
      if (show === '1' || show === '2' || show === '3' || show === '4' || show === '5' || show ===
        '6') {
        show = parseInt(show)
        this.showTab = show - 1
        this.tabLoading = true
        await this.judgeTab(this.tabList[this.showTab])
        this.tabLoading = false
        return
      }
      const tab4time = [
        {
          tab: 0,
          start: '2021/8/1 00:00:00',
          end: '2021/8/3 23:59:59'
        }, {
          tab: 1,
          start: '2021/8/4 00:00:00',
          end: '2021/8/10 11:59:59'
        }, {
          tab: 2,
          start: '2021/8/10 12:00:00',
          end: '2021/8/17 23:59:59'
        }, {
          tab: 3,
          start: '2021/8/18 00:00:00',
          end: '2021/8/24 23:59:59'
        }, {
          tab: 4,
          start: '2021/8/25 00:00:00',
          end: '2021/8/26 23:59:59'
        }, {
          tab: 5,
          start: '2021/8/27 00:00:00',
          end: '2022/12/31 23:59:59'
        }
      ]
      const now = await this.getServerTime()
      tab4time.some(ele => {
        const start = this.$day(ele.start)
        const end = this.$day(ele.end)
        if (now > start && now <= end) {
          this.showTab = ele.tab
          return true
        } else {
          return false
        }
      })
      this.tabLoading = true
      await this.judgeTab(this.tabList[this.showTab])
      this.tabLoading = false
    },
    async clickTab(item) {
      this.showTab = item.tag
      this.tabLoading = true
      await this.judgeTab(item)
      this.tabLoading = false
    },
    async judgeTab(item) {
      const now = await this.getServerTime()
      const closeTime = this.$day(item.endTime)
      item.close = now > closeTime
      if (this.showAllTabs) {
        item.open = true
        return
      }
      const openTime = this.$day(item.startTime)
      if (now > openTime) {
        item.open = true
      } else {
        item.open = false
      }
    },
    jumpLink(item) {
      if (item.link) {
        if (item.link.startsWith('https://weixinshu.com')) {
          item.link = item.link.replace('https://weixinshu.com', '')
        }
        if (item.link.startsWith('/')) {
          this.$gtag.event('weixinshu7th_link', {
            event_label: item.value
          })
          return this.$router.push(item.link)
        }
        location.href = item.link
        this.$gtag.event('weixinshu7th_link', {
          event_label: item.value
        })
      }
    },
    handleSigninResult() {
      const days = this.signinData.days
      days.forEach(ele => {
        for (const item in this.signinObj) {
          if (ele === item) {
            this.signinObj[item] = true
          }
        }
      })
    },
    async getSignin() {
      try {
        this.signinData = await this.$req.get('/api/activity/wxs_seventh_anniversary/sign_in')
        this.handleSigninResult()
      } catch (error) {
      }
    },
    async signin() {
      if (this.tabList[1].close) {
        return
      }
      if (this.signinData.todaySigned) {
        this.signinPopup = true
        return
      }
      try {
        await this.$req.post('/api/activity/wxs_seventh_anniversary/sign_in')
        this.$alert.success('签到成功')
        setTimeout(() => {
          this.signinPopup = true
        }, 1000)
      } catch (error) {
      } finally {
        this.getSignin()
      }
    },
    handleFansList() {
      const yu = this.fansList.length % 3
      const times = 3 - yu
      this.inviteFans = times
      for (let i = 0; i < times; i++) {
        this.fansList.push({
          headimgurl: 'https://img.xinshu.me/upload/372227c94e184760841f40e98de0968a'
        })
      }
      this.inviteSwiperOption.initialSlide = this.fansList.length
      this.showFans = true
    },
    async getFans() {
      try {
        this.fansList = await this.$req.get('/api/activity/wxs_seventh_anniversary/pagent/fans')
        this.handleFansList()
      } catch (error) {
      }
    },
    fetchProfile() {
      return this.$ajax.fetchUserProfile().then(result => {
        this.qrTicket = result.qrTicket
      })
    },
    async getServerTime() {
      let now = ''
      try {
        const result = await this.$req.get('/api/activity/server_time')
        now = this.$day(result.now)
      } catch (error) {
        now = this.$day()
      }
      return now
    },
    goRecharge() {
      if (this.tabList[3].close) {
        return
      }
      this.$router.push('/my/recharge')
    },
    goDiscount() {
      if (this.tabList[2].close) {
        return
      }
      this.$router.push('/books')
    },
    goSpike() {
      if (this.tabList[4].close) {
        return
      }
      this.$router.push('/promotions/panic-buy')
    },
    goGame() {
      if (this.tabList[5].close) {
        return
      }
      this.$router.push('/games/color-blindness')
    },
    inviteRules() {
      this.$dialog.confirm({
        title: '邀请规则',
        content: [
          '① 活动时间：8月1日至8月31日',
          '',
          '② 参与方式：用户点击「邀请好友」按钮，分享海报，好友关注公众号后即为完成一次邀请动作。每邀请3名好友关注公众号，即可获得价值48元A4轻奢杂志册优惠券一张。注：邀请的好友需为心书新用户',
          '',
          '③ 奖励说明：活动期间最高获得7张A4轻奢杂志册优惠券奖励',
          '',
          '④ 所得优惠券可在我的—优惠券中查看，请在领取后60天内使用完毕'
        ].join('<br>'),
        okTitle: '我知道了',
        okOnly: true
      })
    },
    signRules() {
      this.$dialog.confirm({
        title: '签到规则',
        content: [
          '① 活动时间：8月4日至8月10日',
          '',
          '② 签到奖励说明：',
          '签到第1天，可获得一张7元全场券（满10元可用）',
          '签到第2天，可获得一张14元全场券（满20元可用）',
          '签到第3天，可获得一张21元全场券（满30元可用）',
          '签到第4天，可获得一张28元全场券（满40元可用）',
          '签到第5天，可获得一张35元全场券（满50元可用）',
          '签到第6天，可获得一张42元全场券（满60元可用）',
          '签到第7天，可获得一张49元全场券（满70元可用）',
          '',
          '③ 中断签到说明：若您中断签到，则奖励从第1天开始计算',
          '',
          '④ 所得优惠券可在我的——优惠券中查看，请在领取后7天内使用'
        ].join('<br>'),
        okTitle: '我知道了',
        okOnly: true
      })
    },
    prizeRules() {
      this.$dialog.confirm({
        title: '奖品说明',
        content: [
          '1. 8.1-8.31，用户下单满199元，将100%获得好礼',
          '',
          '2. 奖品说明：',
          '① 戴森吹风机（价值3190元）数量1',
          '② 富士拍立得相机（价值599元）数量1',
          '③ 心书无门槛优惠券（价值777元）数量1',
          '④ 5元余额 数量1000',
          '⑤ 心书皮面册优惠券（价值48元）数量3000',
          '⑥ A4轻奢杂志册优惠券（价值48元）数量3000',
          '⑦ 朋友圈相框摆台优惠券（价值38元）数量1000',
          '⑧ 横款照片书优惠券（价值58元）数量1000',
          '⑨ 亲子笔记本优惠券（价值39元）数量1000',
          '⑩ 锁线精装照片书优惠券（价值98元）数量1000',
          '以上奖品将根据一定比例随机发放',
          '',
          '3. 其它情况说明：',
          '① 实物奖品（戴森吹风机、富士拍立得相机）需凭微信信息及对应身份信息，在8月31日24点前到客服处核销，逾期即作为失效',
          '② 虚拟奖品（余额/兑换券）将自动发放至您的心书钱包内；虚拟奖品请在领取后60天内使用'
        ].join('<br>'),
        okTitle: '我知道了',
        okOnly: true
      })
    },
    go7year() {
      this.$router.push('/promotions/seven-year')
    }
  }
}
</script>

<style lang="scss">
[data-page="weixinshu7th"] {
  main {
    padding-bottom: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
$themeColor: rgba(255, 195, 90, 1);
$secondColor: rgba(163, 96, 255, 1);

.weixinshu-7th {
  max-width: 480px;
  padding: 0;
  padding-bottom: 2rem;
  background-color: $secondColor;
  @include bgi('https://img.xinshu.me/upload/dfc2fd690b8d45c28045f65a3e3118e6');
  background-size: 100%;
  background-position: top center;
  background-repeat: no-repeat;

  .top {
    position: relative;
    padding: .5rem;

    .all-rule {
      position: absolute;
      width: 20%;
      right: 0;
      top: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .text {
        position: absolute;
        color: #fff;
      }
    }

    .title {
      padding-top: 2rem;
      margin-bottom: 4rem;
      width: 48%;
      margin-left: auto;
      margin-right: auto;
    }

    .subtitle {
      width: 62%;
      margin-left: auto;
      margin-right: auto;
      top: 41.5%;
    }

    .act-box {
      display: flex;

      .item {
        width: 100%;
        padding: .5rem;
      }
    }

    .guides {
      ::v-deep .swiper-slide {
        width: 80%;
      }

      ::v-deep .swiper-slide-active {
        width: 80%;
      }
    }
  }

  .content {
    .tab-box {
      display: flex;
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      .activity-tab {
        width: 22.3%;
        max-width: 22.3%;
        min-width: 22.3%;
        background-color: $themeColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 12px;
        padding: 0.5rem 0;
        cursor: pointer;
        border-width: 1px;
        border-color: $secondColor;
        border-style: none solid solid none;

        &:last-child {
          border-right: none;
        }
      }

      .active {
        background-color: rgb(251, 244, 235);
        color: $themeColor;
        border-bottom: none;
      }
    }

    .activity-box {
      position: relative;
      height: 35vh;
      min-height: 240px;
      background-color: rgb(251, 244, 235);

      .activity-load {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .activity-blank {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .blank-pic {
          width: 26%;
        }

        .blank-text {
          margin-top: 12px;
        }
      }

      .content {
        height: 100%;

        .activity-rule {
          position: absolute;
          width: 18%;
          right: 0;
          top: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 12px;

          .text {
            position: absolute;
            color: #fff;
          }
        }

        .activity-ti {
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          position: absolute;
          width: 100%;
          top: 10%;
        }

        .activity-pic {
          position: absolute;
          top: 26%;
          width: 64%;
          margin-left: 18%;
        }

        .middle-pic {
          position: absolute;
          width: 70%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .activity-btn {
          position: absolute;
          width: 10em;
          margin: auto;
          left: 0;
          right: 0;
          border-radius: 50rem;
          background-color: $themeColor;
          text-align: center;
          color: #fff;
          padding: 8px 0;
          bottom: 8%;
          cursor: pointer;
        }

        .activity-btn-unable {
          background-color: #ccc;
        }
      }

      .sign {
        .sign-box {
          position: absolute;
          top: 31%;
          display: flex;
          justify-content: space-between;
          padding: 0 12px;

          .sign-item {
            position: relative;
            padding: 16px 0 6px 0;
            font-size: 12px;
            background-color: #fff;
            border-radius: 4px;
            flex: 1;
            width: 13%;
            max-width: 13%;
            min-width: 13%;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 60%;
            }

            .text {
              margin-top: 4px;
            }

            .checked {
              position: absolute;
              top: 0;
              right: 0;
              color: #fff;
              font-size: 12px;
              line-height: 1;
              padding: 1px;
              padding-left: 12px;
              padding-bottom: 12px;
              background-color: $themeColor;
              background: linear-gradient(45deg, transparent 18px, $themeColor 0);
              transform-origin: top right;
              transform: scale(0.83333333333);
              border-radius: 0 4px 0 0;
            }
          }
        }

        .sign-remind {
          width: 100%;
          text-align: center;
          position: absolute;
          bottom: 25.5%;
          font-size: 12px;
          text-decoration: underline;
          color: rgb(61, 61, 61);
          cursor: pointer;
        }
      }

      .invite {
        .invite-box {
          position: absolute;
          top: 30%;
          width: 60%;
          margin-left: 20%;

          ::v-deep .swiper-button-prev {
            color: $themeColor;
            left: -30px;
          }

          ::v-deep .swiper-button-next {
            color: $themeColor;
            right: -30px;
          }

          ::v-deep .swiper-button-disabled {
            color: rgba(255, 195, 90, 0.5);
          }

          .invite-item {
            border-radius: 50rem;
            border: $themeColor 2px solid;

            img {
              border-radius: 50rem;
            }
          }
        }

        .invite-text {
          width: 100%;
          font-size: 12px;
          text-align: center;
          position: absolute;
          top: 62%;
        }
      }

      .discount {
        .discount-tip {
          position: absolute;
          width: 100%;
          text-align: center;
          font-size: 0.8rem;
          color: #3d3d3d;
          bottom: 26.5%;
        }
      }

      .recharge {
        .recharge-box {
          position: absolute;
          width: 72%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .recharge-item {
            position: relative;
            width: 28%;
            min-width: 28%;
            max-width: 28%;
            height: 100%;
            @include bgi('https://img.xinshu.me/upload/fe09f9abe3424532ab21cf4e7d8569df');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            .fan {
              width: 100%;
              text-align: center;
              position: absolute;
              top: 38%;
              color: rgba(268, 96, 108);
              font-size: 1.4rem;
              .small {
                font-size: 0.8rem;
              }
            }
            .chong {
              width: 100%;
              text-align: center;
              position: absolute;
              bottom: 35%;
              color: #fff;
            }
            .tip {
              position: absolute;
              top: 69%;
              width: 200%;
              margin-left: -50%;
              text-align: center;
              font-size: 0.8rem;
              color: #3d3d3d;
            }
          }
        }
      }
    }
  }

  .bottom {
    .report {
      cursor: pointer;
    }

    .recommend-box {
      width: 100%;
      background-image: url(https://img.xinshu.me/upload/ee038cc51cb748888d0e1c60f158543d);
      background-repeat: no-repeat;
      background-size: 100% auto;

      .subtitle {
        width: 62%;
        margin-left: 19%;
        padding-top: 6vh;
      }

      .item-outer {
        width: 100%;
        margin-top: 3vh;
        padding: 0 2rem;

        .item-wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .item {
            flex: 1;
            width: 48%;
            min-width: 48%;
            max-width: 48%;
            margin-bottom: 1.5rem;
            color: #fff;
            font-size: 14px;

            .pic {
              border-radius: 8px;
            }

            .ti {
              margin-top: 10px;
            }

            .info {
              font-size: 10px;
              margin: 4px 0;
            }

            .price {
              .qi {
                font-size: 10px;
              }

              .old-price {
                font-size: 10px;
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
  }
}
</style>
